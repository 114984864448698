import "../styles/Subscribe.css"
import React, {useRef,useState} from 'react';
import {Formik} from "formik"
import FormCards from "./FormCards"
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';

function Subscribe(){
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const form = useRef(null);

  return (
    <div className="subscribe">
        <div className="design-1">
          <div className="design-image"></div>
        </div>
        <div className="subscribe-box">
            <div className="subscribe-header">
                <h4>Join Our Mailing List</h4>
                <span>Subscribe to our newsletter to stay connected to our activities, 
                get insights and updates on what's happening in our community!</span>
            </div>

            <Formik >
                {formik =>(
                  <div >
                    <form className="subscribe-form"
                    ref={form}
                    onSubmit={(e) => {
                      e.preventDefault();
                      emailjs.sendForm('service_skicixn', 'template_kfbq8r6', form.current, 'fhQY2nDt6uUEcX2S6')
                      .then((result) => {
                          console.log(result.text);
                          Swal.fire({
                              icon: 'success',
                              title:"You're in",
                          })          
                      }, (error) => {
                          console.log(error.text);
                          Swal.fire({
                              icon: "error",
                              title: "Oops, Something went wrong",
                              text: error.text,
                          })
                      });
                      setName("");
                      setEmail("");
                      }
                  }>
                      <FormCards name="name" value={name} onChange={event => setName(event.target.value)}
                                placeholder="Full Name"
                                type="text" text="form" />
                      <FormCards name="mail" value={email} onChange={event => setEmail(event.target.value)}
                                placeholder="Email Address"
                                type="email" text="form" />
                      <button className="subscribe-btn" type="submit">Subscribe</button>
                    </form>
                  </div>
                )}
            </Formik>
        </div>
        <div className="design-2">
        <div className="design-image1"></div>
        </div>
    </div>
  )
}

export default Subscribe